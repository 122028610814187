var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Header'),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"flexslider"},[_c('div',{staticClass:"block"},[_c('el-carousel',{attrs:{"indicator-position":"none","arrow":"always","height":"520px"}},_vm._l((_vm.banner),function(item,ban){return _c('el-carousel-item',{key:ban,staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":item,"alt":""}})])}),1)],1)])])]),_c('div',{staticClass:"space_hx"},[_vm._v(" ")]),_c('div',{staticClass:"index_card"},[_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("商会介绍")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/introduce',
            query: { id: 101, name: '商会介绍' },
          }}},[_vm._v("MORE>>")])],1),_vm._m(0)]),_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("商会新闻")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"href":"","to":{
            path: '/introduce',
            query: { id: 102, name: '商会新闻' },
          }}},[_vm._v("MORE>>")])],1),_c('div',{staticClass:"i_b_img"},_vm._l((_vm.xwlist),function(item,xw){return _c('router-link',{key:xw,staticClass:"i_b_ul",attrs:{"to":{
            path: '/detail',
            query: {
              id: item.id,
            },
          }}},[_c('div',{staticClass:"i_b_yuan"}),_c('div',{staticClass:"i_b_span"},[_c('div',{staticClass:"i_b_wen"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"i_b_date"},[_vm._v(_vm._s(item.createTime))])])])}),1)]),_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("商会公告")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/introduce',
            query: { id: 102, name: '商会新闻' },
          }}},[_vm._v("MORE>>")])],1),_c('div',{staticClass:"i_b_img"},_vm._l((_vm.gglist),function(item,gg){return _c('router-link',{key:gg,staticClass:"i_b_ul",attrs:{"to":{
            path: '/detail',
            query: {
              id: item.id,
            },
          }}},[_c('div',{staticClass:"i_b_yuan"}),_c('div',{staticClass:"i_b_span"},[_c('div',{staticClass:"i_b_wen"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"i_b_date"},[_vm._v(_vm._s(item.createTime))])])])}),1)])]),_c('div',{staticClass:"index_card"},[_c('div',{staticClass:"indexbox",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("商会会员")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/vip',
          }}},[_vm._v("MORE>>")])],1),_c('div',{staticClass:"i_b_img i_b_vipimg"},_vm._l((_vm.viplist),function(item,vip){return _c('div',{key:vip,staticClass:"i_b_vip"},[_c('router-link',{attrs:{"to":{
              path: '/Member',
              query: { setid: item.id },
            }}},[_c('img',{attrs:{"src":item.images,"alt":""}})])],1)}),0)])]),_c('div',{staticClass:"index_card"},[_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("走近新加坡")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/introduce',
            query: { id: 103, name: '走近新加坡' },
          }}},[_vm._v("MORE>>")])],1),_c('div',{staticClass:"i_b_img"},_vm._l((_vm.xjplist),function(item,xjp){return _c('router-link',{key:xjp,staticClass:"i_b_ul",attrs:{"to":{
            path: '/detail',
            query: {
              id: item.id,
            },
          }}},[_c('div',{staticClass:"i_b_yuan"}),_c('div',{staticClass:"i_b_span"},[_c('div',{staticClass:"i_b_wen"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"i_b_date"},[_vm._v(_vm._s(item.createTime))])])])}),1)]),_c('div',{staticClass:"indexbox"},[_vm._m(1),_c('div',{staticClass:"i_b_img"},[_c('el-carousel',{attrs:{"arrow":"never","height":"230px"}},[_c('el-carousel-item',{staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":require("../assets/images/5337612.jpg"),"alt":""}})]),_c('el-carousel-item',{staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":require("../assets/images/5337581.jpg"),"alt":""}})]),_c('el-carousel-item',{staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":require("../assets/images/5337304.jpg"),"alt":""}})]),_c('el-carousel-item',{staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":require("../assets/images/5337436.jpg"),"alt":""}})]),_c('el-carousel-item',{staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":require("../assets/images/5339944.jpg"),"alt":""}})]),_c('el-carousel-item',{staticClass:"transition-box"},[_c('img',{staticClass:"transition-box",attrs:{"src":require("../assets/images/5337604.jpg"),"alt":""}})])],1)],1)]),_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("走进山西")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/introduce',
            query: { id: 104, name: '走进山西' },
          }}},[_vm._v("MORE>>")])],1),_vm._m(2)])]),_vm._m(3),_c('div',{staticClass:"index_card"},[_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("投资交流")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/introduce',
            query: { id: 102, name: '商会新闻' },
          }}},[_vm._v("MORE>>")])],1),_c('div',{staticClass:"i_b_img"},_vm._l((_vm.jllist),function(item,jl){return _c('router-link',{key:jl,staticClass:"i_b_ul",attrs:{"to":{
            path: '/detail',
            query: {
              id: item.id,
            },
          }}},[_c('div',{staticClass:"i_b_yuan"}),_c('div',{staticClass:"i_b_span"},[_c('div',{staticClass:"i_b_wen"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"i_b_date"},[_vm._v(_vm._s(item.createTime))])])])}),1)]),_c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("商会文化")]),_c('router-link',{staticClass:"i_b_mo",attrs:{"to":{
            path: '/introduce',
            query: { id: 102, name: '商会新闻' },
          }}},[_vm._v("MORE>>")])],1),_c('div',{staticClass:"i_b_img"},_vm._l((_vm.whlist),function(item,wh){return _c('router-link',{key:wh,staticClass:"i_b_ul",attrs:{"to":{
            path: '/detail',
            query: {
              id: item.id,
            },
          }}},[_c('div',{staticClass:"i_b_yuan"}),_c('div',{staticClass:"i_b_span"},[_c('div',{staticClass:"i_b_wen"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"i_b_date"},[_vm._v(_vm._s(item.createTime))])])])}),1)]),_vm._m(4)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"i_b_img",attrs:{"href":""}},[_c('img',{attrs:{"src":require("../assets/images/5337433.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("活动剪影")]),_c('a',{staticClass:"i_b_mo",attrs:{"href":""}},[_vm._v("MORE>>")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"i_b_img i_b_img_p",attrs:{"href":"/detail?id=10"}},[_c('img',{attrs:{"src":require("../assets/images/5337327.jpeg"),"alt":""}}),_c('div',{staticClass:"i_b_img_jieshao"},[_vm._v("山西，正在向世界发出盛情的邀请！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_banner"},[_c('img',{attrs:{"src":require("../assets/images/-42034.png"),"alt":""}}),_c('div',{staticClass:"i_d_ban_h"},[_c('h1',[_vm._v("立志弘扬商会文化、构建和谐社会")]),_c('div',{staticClass:"i_d_ban_box"},[_c('div',{staticClass:"i_d_ban_heng"}),_c('p',[_vm._v("凝聚会员合力携手共谋发展")]),_c('div',{staticClass:"i_d_ban_heng"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indexbox"},[_c('div',{staticClass:"i_b_t"},[_c('span',[_vm._v("联系我们")]),_c('a',{staticClass:"i_b_mo",attrs:{"href":""}},[_vm._v("MORE>>")])]),_c('div',{staticClass:"i_b_img"},[_c('img',{staticClass:"index_tel",attrs:{"src":require("../assets/images/5344321.png"),"alt":""}})])])
}]

export { render, staticRenderFns }