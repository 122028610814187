<template>
  <div>
    <!--头部-->
    <div class="header">
      <div class="head clearfix">
        <div class="logo">
          <a href="/#/"><img src="../assets/images/logo.png" alt="晋商" /></a>
        </div>
        <div class="head_r">
          <div class="search">
            <input
              name=""
              type="text"
              class="text"
              v-model="inputVal"
              placeholder="请输入关键词"
            />
            <button
              name=""
              type="submit"
              class="btn"
              value=""
              @click="search(inputVal)"
            >
              <img src="../assets/images/s_btn.png" alt="" class="h_search" />
              搜索
            </button>
          </div>
          <div class="tel">
            <img src="../assets/images/ewm.jpg" alt="" class="ewm" />
            <div class="tel_p">
              <p>扫描二维码</p>
              <p>关注官方微信</p>
            </div>
          </div>
        </div>
      </div>
      <div class="space_hx">&nbsp;</div>

      <div class="nav_m">
        <el-menu
          router
          :default-active="this.$route.path"
          class="el-menu-demo el_menu"
          mode="horizontal"
          @select="handleSelect"
          background-color="#8B2722"
          text-color="#fff"
          active-text-color="#000"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu
            v-for="(item, menuitem) in menu"
            :key="menuitem"
            :index="item.id.toString()"
            @click.native="handleSelectMenu(item.id, item.name)"
          >
            <!-- 商会介绍 -->
            <template slot="title"
              ><span slot="title">{{ item.name }}</span></template
            >
            <el-menu-item
              v-for="(index, childitem) in item.children"
              :key="childitem"
              :index="index.id.toString()"
              :route="{
                path: '/introduce',
                query: {
                  id: item.id,
                  name: item.name,
                  childid: index.id,
                  childname: index.name,
                },
              }"
              ><span slot="title">{{ index.name }}</span></el-menu-item
            >
          </el-submenu>
          <el-menu-item index="/vip"> 商会会员</el-menu-item>
        </el-menu>
        <el-button
          class="nav_menu"
          @click="draw()"
          type="warning"
          icon="el-icon-s-fold"
        >
        </el-button>

        <el-menu
          router
          :default-active="this.$route.path"
          class="el-menu-vertical-demo"
          @select="handleSelect"
          v-if="drawer"
          background-color="#8B2722"
          text-color="#fff"
          active-text-color="#000"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu
            v-for="(item, menuitem) in menu"
            :key="menuitem"
            :index="item.id.toString()"
            @click.native="handleSelectMenu(item.id, item.name)"
          >
            <template slot="title"
              ><span slot="title">{{ item.name }}</span></template
            >
            <el-menu-item
              v-for="(index, childitem) in item.children"
              :key="childitem"
              :index="index.id.toString()"
              :route="{
                path: '/introduce',
                query: {
                  id: item.id,
                  name: item.name,
                  childid: index.id,
                  childname: index.name,
                },
              }"
              ><span slot="title">{{ index.name }}</span></el-menu-item
            >
          </el-submenu>
          <el-menu-item index="/vip"> 商会会员</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
export default {
  data() {
    return {
      menu: [],
      inputVal: "",
      drawer: false,
      direction: "ltr",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    search(curVal) {
      this.$router.push({ path: "/search", query: { curVal: curVal } });
    },
    handleSelect(key, keyPath) {},
    handleSelectMenu(id, name) {
      this.$router.push({
        path: "/introduce",
        query: { id: id, name: name },
      });
    },
    getList() {
      this.$axios
        .get("/api/templateOne/directoryInfo/1/")
        .then((res) => {
          this.menu = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    draw() {
      if (this.drawer == false) {
        this.drawer = true;
      } else {
        this.drawer = false;
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/css/reset.css";
@import "../assets/plugins/FlexSlider/flexslider.css";
@import "../assets/css/thems.css";
@import "../assets/css/responsive.css";
.el-button{
  position: absolute;
  right: 0;
  margin-right: 5px;
  margin-top: 5px;
  z-index: 3;
}
.el-menu-item:hover{
  height: 60px !important;
}
</style>