<template>
  <div class="home">
    <!--头部-->
    <Header></Header>
    <!--幻灯片-->
    <div class="banner">
      <div class="slider">
        <div class="flexslider">
          <div class="block">
            <el-carousel
              indicator-position="none"
              arrow="always"
              height="520px"
            >
              <el-carousel-item
                class="transition-box"
                v-for="(item, ban) in banner"
                :key="ban"
              >
                <img class="transition-box" :src="item" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="space_hx">&nbsp;</div>
    <!-- <div class="i_box clearfix">
      <div class="i_bl">
        <div class="box_h"><span>PPP免费咨询</span></div>
        <div class="box_m">
          <dl class="clearfix">
            <dt><img src="../assets/images/icon2.png" alt="" /></dt>
            <dd>
              <b>4009978555</b>
              <p>(请拨以下分机号)</p>
            </dd>
          </dl>
          <p>海绵城市<em>"1"</em></p>
          <p>咨询陪训<em>"2"</em></p>
          <p>综合管廊<em>"3"</em></p>
          <p>项目基金<em>"4"</em></p>
          <p>流域治理<em>"5"</em></p>
          <p>网络繁忙<em>"6"</em></p>
        </div>
      </div>
      <div class="i_br">
        <div class="box_h">
          <span>PPP项目实操平台培训</span>
          <a href=""><em>+</em> MORE</a>
        </div>
        <div class="box_m">
          <ul class="ul_a clearfix">
            <li>
              <a href=""
                ><img src="../assets/images/pic1.jpg" alt="" /><span
                  >陪训课程</span
                ></a
              >
            </li>
            <li>
              <a href=""
                ><img src="../assets/images/pic2.jpg" alt="" /><span
                  >项目动态</span
                ></a
              >
            </li>
            <li>
              <a href=""
                ><img src="../assets/images/pic3.jpg" alt="" /><span
                  >专家中心</span
                ></a
              >
            </li>
          </ul>
          <div class="space_hx">&nbsp;</div>
          <ul class="ul_b clearfix">
            <li>
              <a href=""><img src="../assets/upload/pic1.jpg" alt="" /></a>
            </li>
            <li>
              <a href=""><img src="../assets/upload/pic2.jpg" alt="" /></a>
            </li>
            <li>
              <a href=""><img src="../assets/upload/pic3.jpg" alt="" /></a>
            </li>
            <li>
              <a href=""><img src="../assets/upload/pic4.jpg" alt="" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <!-- 商会介绍 -->
    <div class="index_card">
      <div class="indexbox">
        <div class="i_b_t">
          <span>商会介绍</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 101, name: '商会介绍' },
            }"
            >MORE>></router-link
          >
        </div>
        <a href="" class="i_b_img">
          <img src="../assets/images/5337433.jpg" alt="" />
        </a>
      </div>
      <div class="indexbox">
        <div class="i_b_t">
          <span>商会新闻</span>
          <router-link
            href=""
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 102, name: '商会新闻' },
            }"
            >MORE>></router-link
          >
        </div>
        <div class="i_b_img">
          <router-link
            class="i_b_ul"
            v-for="(item, xw) in xwlist"
            :key="xw"
            :to="{
              path: '/detail',
              query: {
                id: item.id,
              },
            }"
          >
            <div class="i_b_yuan"></div>
            <div class="i_b_span">
              <div class="i_b_wen">{{ item.title }}</div>
              <div class="i_b_date">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="indexbox">
        <div class="i_b_t">
          <span>商会公告</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 102, name: '商会新闻' },
            }"
            >MORE>></router-link
          >
        </div>
        <div class="i_b_img">
          <router-link
            class="i_b_ul"
            v-for="(item, gg) in gglist"
            :key="gg"
            :to="{
              path: '/detail',
              query: {
                id: item.id,
              },
            }"
          >
            <div class="i_b_yuan"></div>
            <div class="i_b_span">
              <div class="i_b_wen">{{ item.title }}</div>
              <div class="i_b_date">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- 商会会员 -->
    <div class="index_card">
      <div class="indexbox" style="width: 100%">
        <div class="i_b_t">
          <span>商会会员</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/vip',
            }"
            >MORE>></router-link
          >
        </div>
        <div class="i_b_img i_b_vipimg">
          <div class="i_b_vip" v-for="(item, vip) in viplist" :key="vip">
            <router-link
              :to="{
                path: '/Member',
                query: { setid: item.id },
              }"
            >
              <img :src="item.images" alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 走近新加坡 -->
    <div class="index_card">
      <div class="indexbox">
        <div class="i_b_t">
          <span>走近新加坡</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 103, name: '走近新加坡' },
            }"
            >MORE>></router-link
          >
        </div>
        <div class="i_b_img">
          <router-link
            class="i_b_ul"
            v-for="(item, xjp) in xjplist"
            :key="xjp"
            :to="{
              path: '/detail',
              query: {
                id: item.id,
              },
            }"
          >
            <div class="i_b_yuan"></div>
            <div class="i_b_span">
              <div class="i_b_wen">{{ item.title }}</div>
              <div class="i_b_date">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="indexbox">
        <div class="i_b_t">
          <span>活动剪影</span>
          <a href="" class="i_b_mo">MORE>></a>
        </div>
        <div class="i_b_img">
          <el-carousel arrow="never" height="230px">
            <el-carousel-item class="transition-box">
              <img
                class="transition-box"
                src="../assets/images/5337612.jpg"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item class="transition-box">
              <img
                class="transition-box"
                src="../assets/images/5337581.jpg"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item class="transition-box">
              <img
                class="transition-box"
                src="../assets/images/5337304.jpg"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item class="transition-box">
              <img
                class="transition-box"
                src="../assets/images/5337436.jpg"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item class="transition-box">
              <img
                class="transition-box"
                src="../assets/images/5339944.jpg"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item class="transition-box">
              <img
                class="transition-box"
                src="../assets/images/5337604.jpg"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="indexbox">
        <div class="i_b_t">
          <span>走进山西</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 104, name: '走进山西' },
            }"
            >MORE>></router-link
          >
        </div>
        <a href="/detail?id=10" class="i_b_img i_b_img_p">
          <img src="../assets/images/5337327.jpeg" alt="" />
          <div class="i_b_img_jieshao">山西，正在向世界发出盛情的邀请！</div>
        </a>
      </div>
    </div>
    <!-- banner图 -->
    <div class="index_banner">
      <img src="../assets/images/-42034.png" alt="" />
      <div class="i_d_ban_h">
        <h1>立志弘扬商会文化、构建和谐社会</h1>
        <!-- <el-divider content-position="center"
          >凝聚会员合力携手共谋发展</el-divider
        > -->
        <div class="i_d_ban_box">
          <div class="i_d_ban_heng"></div>
          <p>凝聚会员合力携手共谋发展</p>
          <div class="i_d_ban_heng"></div>
        </div>
      </div>
    </div>
    <!-- 投资交流 -->
    <div class="index_card">
      <div class="indexbox">
        <div class="i_b_t">
          <span>投资交流</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 102, name: '商会新闻' },
            }"
            >MORE>></router-link
          >
        </div>
        <div class="i_b_img">
          <router-link
            class="i_b_ul"
            v-for="(item, jl) in jllist"
            :key="jl"
            :to="{
              path: '/detail',
              query: {
                id: item.id,
              },
            }"
          >
            <div class="i_b_yuan"></div>
            <div class="i_b_span">
              <div class="i_b_wen">{{ item.title }}</div>
              <div class="i_b_date">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="indexbox">
        <div class="i_b_t">
          <span>商会文化</span>
          <router-link
            class="i_b_mo"
            :to="{
              path: '/introduce',
              query: { id: 102, name: '商会新闻' },
            }"
            >MORE>></router-link
          >
        </div>
        <div class="i_b_img">
          <router-link
            class="i_b_ul"
            v-for="(item, wh) in whlist"
            :key="wh"
            :to="{
              path: '/detail',
              query: {
                id: item.id,
              },
            }"
          >
            <div class="i_b_yuan"></div>
            <div class="i_b_span">
              <div class="i_b_wen">{{ item.title }}</div>
              <div class="i_b_date">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="indexbox">
        <div class="i_b_t">
          <span>联系我们</span>
          <a href="" class="i_b_mo">MORE>></a>
        </div>
        <div class="i_b_img">
          <img class="index_tel" src="../assets/images/5344321.png" alt="" />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      xwlist: [],
      gglist: [],
      xjplist: [],
      jllist: [],
      whlist: [],
      viplist: [],
      pageNum: 1,
      pageSize: 6,
      banner: [],
    };
  },
  mounted() {
    this.getbanner();
    this.getvip();
    this.getxwlist();
    this.getgglist();
    this.getxjplist();
    this.getjllist();
    this.getwhlist();
    this.getvisit();
  },
  methods: {
    // banner
    getbanner() {
      this.$axios
        .get("/api/templateOne/getBanner/1")
        .then((res) => {
          this.banner = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 商会新闻
    getxwlist() {
      var data = {
        directoryId: 102,
        siteId: 1,
        pageNum: this.pageNum,
        size: this.pageSize,
      };
      this.$axios
        .post("/api/templateOne/articleInfo", data)
        .then((res) => {
          this.xwlist = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 商会公告
    getgglist() {
      var data = {
        directoryId: 112,
        siteId: 1,
        pageNum: this.pageNum,
        size: this.pageSize,
      };
      this.$axios
        .post("/api/templateOne/articleInfo", data)
        .then((res) => {
          this.gglist = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 新加坡
    getxjplist() {
      var data = {
        directoryId: 103,
        siteId: 1,
        pageNum: this.pageNum,
        size: this.pageSize,
      };
      this.$axios
        .post("/api/templateOne/articleInfo", data)
        .then((res) => {
          this.xjplist = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 投资交流
    getjllist() {
      var data = {
        directoryId: 120,
        siteId: 1,
        pageNum: this.pageNum,
        size: this.pageSize,
      };
      this.$axios
        .post("/api/templateOne/articleInfo", data)
        .then((res) => {
          this.jllist = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 商会文化
    getwhlist() {
      var data = {
        directoryId: 119,
        siteId: 1,
        pageNum: this.pageNum,
        size: this.pageSize,
      };
      this.$axios
        .post("/api/templateOne/articleInfo", data)
        .then((res) => {
          this.whlist = res.data.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 商会会员
    getvip() {
      var data = {
        productLabel: "商会会员",
        siteId: 1,
        pageNum: this.pageNum,
        size: 100,
      };
      this.$axios
        .post("/api/templateOne/productInfo", data)
        .then((res) => {
          var arr = res.data.rows;
          var viplitsts = [];
          for (var i = 0; i < arr.length; i++) {
            if (i == 0) {
              viplitsts.push(arr[i]);
            }
            if (i == 1) {
              viplitsts.push(arr[i]);
            }
            if (i == 2) {
              viplitsts.push(arr[i]);
            }
            if (i == 6) {
              viplitsts.push(arr[i]);
            }
            if (i == 7) {
              viplitsts.push(arr[i]);
            }
          }
          this.viplist = viplitsts;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getvisit() {
      this.$axios
        .get("/api/templateOne/visit/1")
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
@import "../assets/css/reset.css";
@import "../assets/plugins/FlexSlider/flexslider.css";
@import "../assets/css/thems.css";
@import "../assets/css/responsive.css";

/deep/ .el-carousel__item img {
  width: 100%;
  height: 100%;
}

/deep/ .el-carousel__button {
  width: 8px !important;
}
.transition-box {
  object-fit: cover !important;
}
</style>
