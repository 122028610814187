import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Axios from 'axios'

Axios.defaults.baseURL = process.env.VUE_APP_BASE_API
Axios.defaults.timeout = 10000;  //超时设置
Axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$axios = Axios

Vue.use(ElementUI);

Vue.config.productionTip = false


new Vue({
  components: { App },
  template: '<App/>',
  data: {
    keywords: "",
    description: "",
    title: "",
    viewId: 1,
    status: '',
    path:'/',
  },
  created() {
    this.getSEO(this.path);
    this.getscript();
    // 全局配置
    router.beforeEach((to, from, next) => {
      this.getSEO(to.path);
      this.$axios.get("/api/templateOne/getSiteInfo/1").then((response) => {
        this.status = response.data.status;
        if (this.status == 0) {
          next();
        } else {
          if (to.path == '/error') {
            next();
          } else {
            next({path: '/error'})
          }
        }
      }).catch((err) => {
        console.log(err);
      })
    })
  },
  methods: {
    //获取seo
    getSEO(path) {
      this.$axios.post("/api/templateOne/getSeoInfo", {
        "siteId": 1, "uri": path
      }).then((response) => {
        let res = response.data;
        if (response.status == 200) {
          this.keywords = res.keywords;
          this.description = res.description;
          this.title = res.title;
          document.title = this.title || '晋商';
          document.querySelector('meta[name="keywords"]').setAttribute('content', this.keywords)
          document.querySelector('meta[name="description"]').setAttribute('content', this.description)
        } else {
          this.$layer.msg(res.message);
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 百度统计
    getscript() {
      this.$axios.get("/api/templateOne/getPromotionCode/1").then((response) => {
        document.head.insertAdjacentHTML('beforeend', response.data.code);
      }).catch((err) => {
        console.log(err);
      })
    },
  },
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
