<template>
  <div>
    <div class="space_hx">&nbsp;</div>
    <div class="f_bg">
      <div class="foot">
        <div class="f_nav">
          <p>
            友情链接：<a
              v-for="(item, aherf) in herf"
              :key="aherf"
              :href="item.address"
              >{{ item.name }}</a
            >
          </p>
        </div>
        <p>版权所有©新加坡晋商商会 {{ site.filingNumber }} <a href="http://zdzltech.com" style="color: #ddd" target="_blank">
            技术支持：智达众联</a
          ></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      herf: [],
      site: {},
    };
  },
  mounted() {
    this.getHerf();
    this.getsite();
  },
  methods: {
    getHerf() {
      this.$axios
        .get("/api/templateOne/getLink/1")
        .then((res) => {
          this.herf = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getsite() {
      this.$axios
        .get("/api/templateOne/getSiteInfo/1")
        .then((res) => {
          // console.log(res);
          this.site = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
