import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import( '../views/Shvip.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import( '../views/Introduce.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( '../views/News.vue')
  },
  {
    path: '/Member',
    name: 'Member',
    component: () => import( '../views/Memberdetails.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import( '../views/Search.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import( '../views/Detail.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue') //404
  },
  {
    path: '*',
    redirect: '/error',
    hidden: true
  }

]



const router = new VueRouter({
  routes,
  mode:"history"
})

export default router
